import $ from 'fakequery';
import { BaseStorage } from "storages/basestorage";
import { requiring_store } from 'storages/requiringstore';

function UserStorage(state) {
    var storage = BaseStorage({
        'key': 'system_accounts',
        'users': [],
        'refresh_on_post': true,
        'period': 10,
        'name_map': {}
    });
    $.extend(storage, state || {});
    $.extend(storage, {
        ensure_keys: function () {
            var name_map = {};
            $.map(storage.users, function (user) {
                name_map[user.username] = user;
            });
            storage.name_map = name_map;
        }

    });
    return storage;
}

function default_user_storage_callback(props) {
    function create_user_storage() {
        var storage = UserStorage(props);
        storage.poll();
        return storage;
    }
    return create_user_storage;
}

// function requiring_user_store(Component) {
//     return requiring_store(
//         Component,
//         {
//             storage_key: 'system_accounts',
//             create_function: default_user_storage_callback,
//             forms_key: 'system_accounts_forms',
//             prop_function: (props, storage) => {
//                 console.log(`Got user store update`);
//                 return {
//                     users: storage.users,
//                     current_user: storage.current_user,
//                     ...props
//                 };
//             }
//         }
//     );
// }

export {
    UserStorage, default_user_storage_callback,
    // requiring_user_store 
};
