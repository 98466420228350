/* Cepra bridge debugger */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useStorage from 'storages/usestorage';
import {BaseStorage} from 'storages/basestorage';
import { Cookies } from "react-cookie";
import { Typography } from '@material-ui/core';
import ATXTable from 'atxtable/atxtable';
import { CheckCircle } from '@material-ui/icons';
import { Cancel } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

const CepraBridgeStorage = BaseStorage({
    url:'/ceprabridge/1/info',
    form_key: 'status',
    status: null,
    period: 15,
    debug: true,
});
const useStyles = makeStyles(theme => ({
    good: {
        color: theme.palette.success.main,
    },
    bad: {
        color: theme.palette.error.main,
    }
}));

const IdiotLight = (props) => {
    const classes = useStyles();
    const {good, title} = props;
    if (good) {
        return <CheckCircle title={title} size="small" className={classes.good} />
    } else {
        return <Cancel title={title} size="small" className={classes.bad} />
    }
}
const CepraBridgeRestart = (props) => {
    /* Trigger a cepra bridge restart by exiting the pod */
    const [running,setRunning] = React.useState(false);
    const [success,setSuccess] = React.useState(null);
    const cb = React.useCallback(async (evt) =>  {
        setRunning(true);
        const response = await fetch(
            '/m/api/internal/cepra/shutdown',
            {
                method:'post',
                data: {
                },
            }
        )
        setRunning(false);
        if (response.status != 200) {
            setSuccess(false);
        } else {
            setSuccess(response.success);
        }
    },[]);
    let text = 'Shutdown';
    if (running) {
        text = 'Shutting down';
    } else if (success !== null) {
        if (success) {
            text = 'Shut down';
        } else {
            text = 'Failed';
        }
    }
    return <Button onClick={cb} color="primary">Restart</Button>
}


const FactoryCepraBridge = (props) => {

    const [cepraStatus,setCepraStatus] = React.useState();
    const onStorageUpdate = React.useCallback((storage) => {
        setCepraStatus(storage.status);
    },[CepraBridgeStorage]);
    useStorage(CepraBridgeStorage, onStorageUpdate);
    const classes = useStyles();

    if (!cepraStatus) {
        return 'Loading';
    }

    return <div>
        <Typography>Memory <IdiotLight good={cepraStatus.memory_percent<20} /> {Math.round(cepraStatus.memory_percent*10)/10}% <CepraBridgeRestart /></Typography>
        <Typography>Cepra Clients {(cepraStatus.clients && cepraStatus.clients.length)|| 'Loading'}</Typography>
        <ATXTable 
            data={(cepraStatus && cepraStatus.clients)||[]}
            classes={{
                holder: classes.rootTable,
            }}
            filterable={true}
            columns={[
                {
                    'accessor':'unique_key',
                    'Header':'Unique Key',
                    'Cell': (props) => {
                        const {node} = props.row.original;
                        if (node && node.node_id) {
                            return <React.Fragment><a href={`/m/nodes/${node.node_id}`}>{props.value} Edit</a> <a href={`/proxy/access/debug/${node.node_id}`}>Debug</a></React.Fragment>;
                        } else {
                            return `${props.value}`;
                        }
                    },
                },
                {
                    'accessor':'registration.device_mode',
                    'Header':'Boot Mode',
                },
                {
                    'accessor':'node.stb',
                    'Header':'Conf STB?',
                    'Cell': (props) => {
                        const stb = props.value;
                        if (stb) {
                            return <React.Fragment>
                                <IdiotLight good={stb.enabled} title={stb.enabled?'STB Mode':'Non-STB Mode'} />
                                {stb.__pk__?<a href={stb.__url__}>{stb.title}</a>:null}
                            </React.Fragment>;
                        } else {
                            return null;
                        }
                    }
                },
                {
                    'accessor':'node.monitor',
                    'Header':'Conf Monitor?',
                    'Cell': (props) => {
                        const monitor = props.value;
                        if (monitor) {
                            return <React.Fragment><IdiotLight good={!monitor.disabled} title={(!monitor.disabled)?'Monitor Mode':'Non-Monitor Mode'} />
                                {monitor.__pk__?<a href={monitor.__url__}>{monitor.title}</a>:null}
                            </React.Fragment>
                        } else {
                            return null;
                        }
                    }
                },
                {
                    'accessor': 'node.chassis.firmware',
                    'Header': 'Conf Firmware',
                    'Cell': (props) => {
                        const record = props.value;
                        if (record) {
                            return <a href={record.file_url}>{(record.file && record.file.friendly_name)||'unnamed'}</a>
                        } else {
                            return null;
                        }
                    }
                }
            ]}
        />

        <Typography>Cepra Bridge Tasks {(cepraStatus && cepraStatus.task_count)|| 'Loading'}</Typography>
        <ATXTable 
            data={(cepraStatus && cepraStatus.tasks)||[]}
            classes={{
                holder: classes.rootTable,
            }}
            filterable={true}
            columns={[
                {
                    'accessor':'1',
                    'Header':'Name',
                },
                {
                    'accessor':'0',
                    'Header':'Code',
                },
            ]}
        />
    </div>;

};

export default FactoryCepraBridge;