import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { useCurrentUser } from 'withcurrentuser';

const PREFIX = '/m/'; // TODO: should come from config

const DEFAULT_LINKS = [
    { 'label': 'Device Enrollments', 'url': `${PREFIX}list/Enrollment`, 'permission': 'shogunconf.edit_enrollments' },
    { 'label': 'Metric Trigger Scripts', 'url': `${PREFIX}list/Trigger`, 'permission': 'shogunconf.edit_triggers' },
    { 'label': 'Notification Groups', 'url': `${PREFIX}list/NotificationGroup`, 'permission': 'shogunconf.edit_notificationgroups' },
    { 'label': 'Dashboards', 'url': `${PREFIX}list/Dashboard?forms_key=system_forms&dialog_edit=on`, 'permission': 'shogunconf.edit_dashboards' },
];

const EngineeringPage = (props) => {
    const { links = DEFAULT_LINKS } = props;
    const user = useCurrentUser();
    const visible = links.filter(link => user.has_permission(link.permission));
    return <List >
        {
            visible.map((link, i) => <ListItem key={`item-${i}`}><Link to={link.url}>{link.label}</Link></ListItem>)
        }
    </List>;
};

export default EngineeringPage;
